import { render, staticRenderFns } from "./PostGridLayout.vue?vue&type=template&id=43651715&scoped=true&"
import script from "./PostGridLayout.vue?vue&type=script&lang=ts&"
export * from "./PostGridLayout.vue?vue&type=script&lang=ts&"
import style0 from "./PostGridLayout.vue?vue&type=style&index=0&id=43651715&scoped=true&lang=postcss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43651715",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PostItem: require('/codebuild/output/src1230313615/src/multidomain/src/ui/components/PostsGridLayout/PostItem/PostItem.vue').default})
