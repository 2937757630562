

































import Vue from 'vue'

export default Vue.extend({
  name: 'BaseTabs',
  props: {
    tabs: { type: Array, required: true },
    initialTab: { type: Number, default: 0 },
  },
  data() {
    return {
      selectorWidth: null,
      selectorLeftPosition: null,
    }
  },
  mounted() {
    setTimeout(() => {
      this.setTabIndicatorPosition(this.initialTab)
    }, 0)
  },
  methods: {
    setTabIndicatorPosition(index: number): void {
      const [{ offsetWidth, offsetLeft }] = this.$refs[
        `tab${index}`
      ] as HTMLElement[]
      this.selectorWidth = offsetWidth
      this.selectorLeftPosition = offsetLeft
    },
  },
})
