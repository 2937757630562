import { render, staticRenderFns } from "./PostItem.vue?vue&type=template&id=120b1598&scoped=true&"
import script from "./PostItem.vue?vue&type=script&lang=ts&"
export * from "./PostItem.vue?vue&type=script&lang=ts&"
import style0 from "./PostItem.vue?vue&type=style&index=0&id=120b1598&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "120b1598",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseStack: require('/codebuild/output/src1230313615/src/multidomain/src/ui/components/BaseStack.vue').default})
