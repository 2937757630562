
import Vue from 'vue'
import { VNode, CreateElement } from 'vue/types'

export default Vue.extend({
  name: 'RenderlessTabs',
  props: {
    initialTab: { type: Number, default: 0 },
  },
  data() {
    return {
      selectedTab: this.initialTab,
    }
  },
  methods: {
    setSelectedTab(index: number) {
      this.$emit('selected-tab-changed', index)
      this.selectedTab = index
    },
  },
  render(createElement: CreateElement): VNode {
    return createElement(
      'div',
      this.$scopedSlots.default({
        selectedTab: this.selectedTab,
        setSelectedTab: this.setSelectedTab,
      })
    )
  },
})
