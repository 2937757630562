



















import Vue from 'vue'
import BaseTabs from '../Tabs/BaseTabs.vue'
import { Tab } from '~/types/tab'
import { PostResume, PostCategory } from '~/types/post'

export default Vue.extend({
  name: 'CategoryPostTabs',
  components: { BaseTabs },
  data() {
    return {
      solarPosts: [] as PostResume[],
      billingPosts: [] as PostResume[],
      proceduresPosts: [] as PostResume[],
      faqsPosts: [] as PostResume[],
    }
  },

  async fetch() {
    const solar = this.fetchArticlesByCategory('solar')
    const billing = this.fetchArticlesByCategory('factura')
    const procedures = this.fetchArticlesByCategory('tramites')
    const faqs = this.fetchArticlesByCategory('preguntas-frecuentes')
    await Promise.all([solar, billing, procedures, faqs]).then(
      ([solar, billing, procedures, faqs]) => {
        this.solarPosts = solar
        this.billingPosts = billing
        this.proceduresPosts = procedures
        this.faqsPosts = faqs
      }
    )
  },

  computed: {
    tabs(): Tab[] {
      return Object.values(this.$i18n.t('categories') as unknown as Tab[])
    },
    tabsLabels(): string[] {
      return this.tabs.map((tab: Tab) => tab.label)
    },
    tabsName(): string[] {
      return this.tabs.map((tab: Tab) => tab.name)
    },
  },

  methods: {
    fetchArticlesByCategory(category: PostCategory): Promise<PostResume[]> {
      const query = this.$content('blog')
        .only(['title', 'date', 'tags', 'slug', 'thumbnail'])
        .limit(6)
        .sortBy('date', 'desc')
        .where({ tags: category })

      return query.fetch<PostResume[]>() as Promise<PostResume[]>
    },
  },
})
