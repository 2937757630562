import { render, staticRenderFns } from "./CategoryPostTabs.vue?vue&type=template&id=418c469a&scoped=true&"
import script from "./CategoryPostTabs.vue?vue&type=script&lang=ts&"
export * from "./CategoryPostTabs.vue?vue&type=script&lang=ts&"
import style0 from "./CategoryPostTabs.vue?vue&type=style&index=0&id=418c469a&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "418c469a",
  null
  
)

/* custom blocks */
import block0 from "./CategoryPostTabs.vue?vue&type=custom&index=0&blockType=i18n&lang=yml"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PostGridLayout: require('/codebuild/output/src1230313615/src/multidomain/src/ui/components/PostsGridLayout/PostGridLayout.vue').default,PostByCategoryLink: require('/codebuild/output/src1230313615/src/multidomain/src/ui/components/PostByCategoryLink.vue').default,BaseTabs: require('/codebuild/output/src1230313615/src/multidomain/src/ui/components/Tabs/BaseTabs.vue').default})
