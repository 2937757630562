






import Vue from 'vue'
import { PropOptions } from 'vue/types'

import { PostResume } from '~/types/post'

export default Vue.extend({
  name: 'PostGridLayout',
  props: {
    posts: {
      type: Array,
      required: true,
    } as PropOptions<PostResume[]>,
  },
})
